import axios from 'axios';

// const BASEURL =  'https://cisbritishschool.org'; //production
// const BASEURL = 'https://cisbritish.org' //production
// const BASEURL = 'http://citadel-schools.com'; //local
const BASEURL = 'https://demo.schoolsprint.org'; //demo
// const BASEURL = 'http://127.0.0.1:8000'; //locals
const TOKEN = localStorage.getItem('access_token'); //for some reason I cant access the state here, so I got the token direct from the local storage

export default axios.create({
    baseURL: BASEURL,
    headers: {
        'Content-Type': 'application/json',
        // "Content-Type": "multipart/form-data",
        'Authorization': 'Bearer ' + TOKEN
    }
});
