// plugins/permissions.js
import store from '@/store'

export default {
    install: (app) => {
       
        const hasPermission = (permission) => {
            let user_permissions = store.getters.getUserPermissions; 
            let permissions = (user_permissions && user_permissions.length > 0) ? user_permissions.split(',') : [];
            let result = permissions.filter(function (element){ 
                    if(permission == element)
                        return element
            });

            if(result.length > 0)
                return true;
            else 
                return false;
        }

        app.directive('can', (el, binding) => {
            let permission = binding.value;
            if(!hasPermission(permission))
                el.remove()
        })

        const hasAnyPermission = (permissions) => {
            permissions = (permissions && permissions.length > 0) ? permissions.split(',') : [];
            for(let i=0; i < permissions.length; i++){
                if(hasPermission(permissions[i]))
                    return true;
            }
           
            return false;
        }
        
        app.directive('canAny', (el, binding) => {
            let permissions = binding.value;
            if(!hasAnyPermission(permissions))
                el.remove()
        })

        const hasRole = (role) => { 
            let user_roles = store.getters.getUserRoles;
            let roles = (user_roles && user_roles.length > 0) ? user_roles.split(',') : [];
            let result = roles.filter(function (element){
                if(role == element)
                    return element
            });

            if(result.length > 0)
                return true;
            else 
                return false;
        }

        const hasAnyRole = (roles) => {
            roles = (roles && roles.length > 0) ? roles.split(',') : [];
            for(let i=0; i < roles.length; i++){
                if(hasRole(roles[i]))
                    return true;
            }
           
            return false;
        }

        app.directive('userRole', (el, binding) => {
            let roles = binding.value;
            if(!hasAnyRole(roles))
                el.remove()
        })

        app.directive('userNoRole', (el, binding) => {
            let roles = binding.value;
            if(hasAnyRole(roles))
                el.remove()
        })

        app.provide('hasPermission', hasPermission);
        app.provide('hasAnyPermission', hasAnyPermission);
        app.provide('hasRole', hasRole);
        app.provide('hasAnyRole', hasAnyRole);

        // const xyz = {
        //     fun1: () => {
        //         console.log('Hi from fun 1');
        //     },
        //     fun2: () => {
        //         console.log('Hi from fun 2');
        //     },
        //     funG: (num) => {
        //         console.log('Hi from fun ' + num);
        //         console.log('store ' + store.getters.getSchoolNameAbbreviation);
        //     },
        // }

      
        // app.provide('xyz', xyz)

        // inject a globally available $test() method
        // app.config.globalProperties.$test = (key) => {
        //     return key;
        // }
    },

}