import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

// import Home from '../views/Home.vue'

const routes = [
  // Public ----------------------------------------------------------------------
  {
    path: '/',
    name: 'Home',
    component: () => import('@/PublicLayout.vue'),
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'Home',
        component: () => import('@/views/PublicViews/Home.vue')
      },
      {
        path: 'about-us',
        name: 'About-Us',
        component: () => import('@/views/PublicViews/AboutUs.vue')
      },
      {
        path: 'academic',
        name: 'Academic',
        component: () => import('@/views/PublicViews/Academic.vue')
      },
      {
        path: 'admissions',
        name: 'Admissions',
        component: () => import('@/views/PublicViews/Admissions.vue')
      },
      {
        path: 'contact-us',
        name: 'Contact-Us',
        component: () => import('@/views/PublicViews/ContactUs.vue')
      },
      {
        path: 'sign-up',
        name: 'Sign-Up',
        component: () => import('@/views/PublicViews/SignUp.vue'),
        meta: { requireGuest: true }
      },
    ]
  },
  // Dashboard ----------------------------------------------------------------------
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/DashboardLayout.vue'),
    meta: { requireAuth: true },
    redirect: '/dashboard/dhome',
    children: [
      {
        path: 'dhome',
        name: 'Dashboard-Home',
        component: () => import('@/views/DashboardViews/Home.vue')
      },
      //Students Applications
      {
        path: 'applications',
        name: 'Applications',
        component: () => import('@/views/DashboardViews/Applications/Index.vue')
      },
      {
        path: 'applications/create',
        name: 'Applications-Create',
        component: () => import('@/views/DashboardViews/Applications/Add.vue')
      },
      {
        path: 'applications/:id/edit',
        name: 'Applications-Edit',
        component: () => import('@/views/DashboardViews/Applications/Edit.vue'),
        props: true
      },
      {
        path: 'applications/fees/:id',
        name: 'Applications-Fees',
        component: () => import('@/views/DashboardViews/Applications/Payment.vue'),
        props: true
      },
      {
        path: 'applications/statuses/:id',
        name: 'Applications-Statuses-Management',
        component: () => import('@/views/DashboardViews/Applications/Statuses.vue'),
        props: true
      },
      //Payment Profiles 
      {
        path: 'payment-profiles',
        name: 'Payment-Profiles',
        component: () => import('@/views/DashboardViews/PaymentProfiles/Index.vue')
      },
      {
        path: 'payment-profiles/create',
        name: 'Payment-Profiles-Create',
        component: () => import('@/views/DashboardViews/PaymentProfiles/Add.vue')
      },
      {
        path: 'payment-profiles/:id/edit',
        name: 'Payment-Profiles-Edit',
        component: () => import('@/views/DashboardViews/PaymentProfiles/Edit.vue'),
        props: true
      },
      {
        path: 'payment-profiles/:id',
        name: 'Payment-Profiles-Single',
        component: () => import('@/views/DashboardViews/PaymentProfiles/Single.vue'),
        props: true
      },
      //Students Affairs
      {
        path: 'student-affairs',
        name: 'Student-Affairs',
        component: () => import('@/views/DashboardViews/StudentAffairs/Index.vue'),
      },
      {
        path: 'student-affairs/students',
        name: 'Student-Affairs-Students',
        component: () => import('@/views/DashboardViews/StudentAffairs/Students/Index.vue'),
      },
      {
        path: 'student-affairs/students/:id',
        name: 'Student-Affairs-Students-single',
        component: () => import('@/views/DashboardViews/StudentAffairs/Students/Single.vue'),
        props: true
      },
      {
        path: 'student-affairs/students/:id/edit',
        name: 'Student-Affairs-Students-Edit',
        component: () => import('@/views/DashboardViews/StudentAffairs/Students/Edit.vue'),
        props: true
      },
      //Other Fees
      {
        path: 'student-affairs/other-fees/:student_id',
        name: 'Student-Affairs-Other-Fees-Student',
        component: () => import('@/views/DashboardViews/StudentAffairs/OtherFees/Index.vue'),
        props: true
      },
      {
        path: 'student-affairs/other-fees/create/:student_id',
        name: 'Student-Affairs-Other-Fees-Create',
        component: () => import('@/views/DashboardViews/StudentAffairs/OtherFees/Add.vue'),
        props: true
      },
      {
        path: 'student-affairs/other-fees/:id/edit',
        name: 'Student-Affairs-Other-Fees-Edit',
        component: () => import('@/views/DashboardViews/StudentAffairs/OtherFees/Edit.vue'),
        props: true
      },
      {
        path: 'student-affairs/manage-student-credit',
        name: 'Student-Affairs-Manage-Student-Credit',
        component: () => import('@/views/DashboardViews/StudentAffairs/ManageStudentCredit/Index.vue'),
      },
      //eWallet
      {
        path: 'eWallet',
        name: 'eWallet',
        component: () => import('@/views/DashboardViews/eWallet/Index.vue'),
      },
      {
        path: 'eWallet/student-purchase/:student_id',
        name: 'eWallet-Student-Purchase',
        component: () => import('@/views/DashboardViews/eWallet/Students/Purchase.vue'),
        props: true
      },

      //Students Payments
      {
        path: 'student-payments/list',
        name: 'Student-Payments-Childrens-List',
        component: () => import('@/views/DashboardViews/StudentPayments/ChildrensList.vue'),
      },
      {
        path: 'student-payments/:user_id',
        name: 'Student-Payments-Single',
        component: () => import('@/views/DashboardViews/StudentPayments/Single.vue'),
        props: true
      },
      {
        path: 'student-payments/confirm/:userrequest_id',
        name: 'Student-Payments-Payment-Confirmation',
        component: () => import('@/views/DashboardViews/StudentPayments/PaymentConfirmation.vue'),
        props: true
      },

      //financial
      {
        path: 'financials',
        name: 'Financials',
        component: () => import('@/views/DashboardViews/Financial/Index.vue'),
      },
      //financial accounts
      {
        path: 'financial-accounts/list',
        name: 'Financial-Accounts-List',
        component: () => import('@/views/DashboardViews/Financial/Accounts/Index.vue'),
      },
      {
        path: 'financial-accounts/create',
        name: 'Financial-Accounts-Create',
        component: () => import('@/views/DashboardViews/Financial/Accounts/Add.vue'),
      },
      {
        path: 'financial-accounts/:id/edit',
        name: 'Financial-Accounts-Edit',
        component: () => import('@/views/DashboardViews/Financial/Accounts/Edit.vue'),
        props: true
      },
      {
        path: 'financial-accounts/:id',
        name: 'Financial-Accounts-Single',
        component: () => import('@/views/DashboardViews/Financial/Accounts/Single.vue'),
        props: true
      },
      //financial headings
      {
        path: 'financial-headings/list',
        name: 'Financial-Headings-List',
        component: () => import('@/views/DashboardViews/Financial/Headings/Index.vue'),
      },
      //financial transactions
      {
        path: 'financial-transactions/list',
        name: 'Financial-Transactions-List',
        component: () => import('@/views/DashboardViews/Financial/Transactions/Index.vue'),
      },
      {
        path: 'financial-transactions/create',
        name: 'Financial-Transactions-Create',
        component: () => import('@/views/DashboardViews/Financial/Transactions/Add.vue'),
      },
      {
        path: 'financial-transactions/:id/edit',
        name: 'Financial-Transactions-Edit',
        component: () => import('@/views/DashboardViews/Financial/Transactions/Edit.vue'),
        props: true
      },
      {
        path: 'financial-transactions/:id',
        name: 'Financial-Transactions-Single',
        component: () => import('@/views/DashboardViews/Financial/Transactions/Single.vue'),
        props: true
      },
      //financial reports
      {
        path: 'financial-reports/bank-statement',
        name: 'Financial-Reports-Bank-Statement',
        component: () => import('@/views/DashboardViews/Financial/reports/BankStatement.vue'),
      },
      {
        path: 'financial-reports/installments',
        name: 'Financial-Reports-Installments',
        component: () => import('@/views/DashboardViews/Financial/reports/Installments.vue'),
      },

      //Staff Affairs
      {
        path: 'staff-affairs',
        name: 'Staff-Affairs',
        component: () => import('@/views/DashboardViews/StaffAffairs/Index.vue'),
      },
      {
        path: 'staff-affairs/staff',
        name: 'Staff-Affairs-Staff',
        component: () => import('@/views/DashboardViews/StaffAffairs/Staff/Index.vue'),
      },
      {
        path: 'staff-affairs/create',
        name: 'Staff-Affairs-Staff-Create',
        component: () => import('@/views/DashboardViews/StaffAffairs/Staff/Add.vue'),
      },
      {
        path: 'staff-affairs/staff/:id/edit',
        name: 'Staff-Affairs-Staff-Edit',
        component: () => import('@/views/DashboardViews/StaffAffairs/Staff/Edit.vue'),
        props: true
      },
      {
        path: 'staff-affairs/staff/:id',
        name: 'Staff-Affairs-Staff-Single',
        component: () => import('@/views/DashboardViews/StaffAffairs/Staff/Single.vue'),
        props: true
      },

      //Levels
      {
        path: 'levels',
        name: 'Levels',
        component: () => import('@/views/DashboardViews/Levels/Index.vue')
      },
      {
        path: 'levels/create',
        name: 'Levels-Create',
        component: () => import('@/views/DashboardViews/Levels/Add.vue')
      },
      {
        path: 'levels/:id/edit',
        name: 'Levels-Edit',
        component: () => import('@/views/DashboardViews/Levels/Edit.vue'),
        props: true
      },

      //Academic Year
      {
        path: 'academic-years',
        name: 'Academic-Years',
        component: () => import('@/views/DashboardViews/AcademicYears/Index.vue')
      },
      {
        path: 'academic-years/create',
        name: 'Academic-Years-Create',
        component: () => import('@/views/DashboardViews/AcademicYears/Add.vue')
      },
      {
        path: 'academic-years/:id/edit',
        name: 'Academic-Years-Edit',
        component: () => import('@/views/DashboardViews/AcademicYears/Edit.vue'),
        props: true
      },
      {
        path: 'academic-years/:id',
        name: 'Academic-Years-Single',
        component: () => import('@/views/DashboardViews/AcademicYears/Single.vue'),
        props: true
      },

      //Subjects
      {
        path: 'academic-years/:year_id/subjects',
        name: 'Academic-Years-Subjects',
        component: () => import('@/views/DashboardViews/AcademicYears/Subjects/Index.vue'),
        props: true
      },
      {
        path: 'academic-years/:year_id/subjects/create',
        name: 'Academic-Years-Subjects-Create',
        component: () => import('@/views/DashboardViews/AcademicYears/Subjects/Add.vue'),
        props: true
      },
      {
        path: 'academic-years/subjects/:id/edit',
        name: 'Academic-Years-Subjects-Edit',
        component: () => import('@/views/DashboardViews/AcademicYears/Subjects/Edit.vue'),
        props: true
      },
      {
        path: 'academic-years/subjects/:id',
        name: 'Academic-Years-Subjects-Single',
        component: () => import('@/views/DashboardViews/AcademicYears/Subjects/Single.vue'),
        props: true
      },

      //Buses
      {
        path: 'buses',
        name: 'Buses',
        component: () => import('@/views/DashboardViews/Buses/Index.vue')
      },
      {
        path: 'buses/create',
        name: 'Buses-Create',
        component: () => import('@/views/DashboardViews/Buses/Add.vue')
      },
      {
        path: 'buses/:id/edit',
        name: 'Buses-Edit',
        component: () => import('@/views/DashboardViews/Buses/Edit.vue'),
        props: true
      },
      {
        path: 'buses/:id/show',
        name: 'Buses-Show',
        component: () => import('@/views/DashboardViews/Buses/Single.vue'),
        props: true
      },

      //Teacher
      {
        path: 'teachers/subjects/my',
        name: 'Teachers-Subjects-My',
        component: () => import('@/views/DashboardViews/Teachers/Subjects/My.vue'),
        props: true
      },

      //Parent
      {
        path: 'parent/subjects/std-list',
        name: 'Parent-Subjects-Childrens-List',
        component: () => import('@/views/DashboardViews/Parents/Subjects/ChildrensList.vue'),
      },
      {
        path: 'parent/subjects/list/:student_id',
        name: 'Parent-Subjects-list',
        component: () => import('@/views/DashboardViews/Parents/Subjects/My.vue'),
        props: true
      },
      {
        path: 'parent/subjects/:id',
        name: 'Parent-Subjects-Single',
        component: () => import('@/views/DashboardViews/Parents/Subjects/Single.vue'),
        props: true
      },
      {
        path: 'parent/ewallet',
        name: 'Parent-eWallet',
        component: () => import('@/views/DashboardViews/Parents/eWallet/Index.vue'),
        props: true
      },
      {
        path: 'parent/buses',
        name: 'Parent-Buses',
        component: () => import('@/views/DashboardViews/Parents/Buses/Index.vue'),
        props: true
      },
      {
        path: 'parent/buses/:id',
        name: 'Parent-Buses-Single',
        component: () => import('@/views/DashboardViews/Parents/Buses/Single.vue'),
        props: true
      },

      {
        path: 'ewallet-qrcode',
        name: 'eWallet-qrCode',
        component: () => import('@/views/DashboardViews/Parents/eWallet/QrCode.vue'),
        props: true
      },


      {
        path: 'haitham',
        name: 'Haitham',
        component: () => import('@/views/DashboardViews/Haitham.vue')
      },

    ]
  },
  {
    path: '/test3',
    name: 'test3',
    component: () => import('@/TestLayout.vue'),
    redirect: '/test3/home',
    children: [
      {
        path: '/test3/home',
        name: 'Test3-Home',
        component: () => import('@/Test.vue')
      },
    ]
  },
  //Not found
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import('@/views/404.vue'),
  },
  {
    path: '/401',
    name: '401',
    component: () => import('@/views/401.vue'),
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) { //jump to the top of the window
    return window.scrollTo(0, 0);
  },
  linkActiveClass: "xyzz"
})

// Router guard 
router.beforeEach((to, from, next) => {
  store.commit('setNavbarCollapsed', false);
  if (to.matched.some(record => record.meta.requireAuth) && !store.getters.isAuthenticated && to.name !== 'Sign-Up') window.location.assign('/sign-up?redirect=' + to.fullPath); //next({ name: 'Sign-Up', query: { redirect: to.fullPath} });
  else if (to.matched.some(record => record.meta.requireGuest) && store.getters.isAuthenticated) next({ name: 'Home' });
  else next();
})

export default router
