import {
    createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueSweetAlert from 'vue-sweetalert2'
import permissions from './plugins/permissions'
import 'sweetalert2/dist/sweetalert2.min.css'
import helpers from './plugins/helpers'
import Select2 from 'vue3-select2-component';



createApp(App)
    .use(store)
    .use(router)
    .use(VueSweetAlert)
    .use(permissions)
    .use(helpers)
    .use(Select2)
    .mount('#app')