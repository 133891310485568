import { createStore } from 'vuex'
import axios from '@/axios';
import router from '@/router';

export default createStore({
  state: {
    // domain: 'https://cisbritishschool.org', //production
    // domain: 'https://cisbritish.org', //production
    // domain: 'http://citadel-schools.com/', //local
    domain: 'https://demo.schoolsprint.org', //demo
    // domain: 'http://127.0.0.1:8000', //local
    school_name: 'Citadel International School',
    school_name_abbreviation: 'CBIS school',
    access_token: localStorage.getItem('access_token') || null,
    user_permissions: localStorage.getItem('user_permissions') || [],
    user_roles: localStorage.getItem('user_roles') || [],
    user_first_name: localStorage.getItem('user_first_name') || '',
    user_id: localStorage.getItem('user_id') || '',
    logged_in_user: localStorage.getItem('logged_in_user') || '',
    token_type: null,
    user_payment_request: [],
    navbar_collapsed: false
  },
  getters: {
    isAuthenticated: () => localStorage.getItem('access_token') != null,
    getAccessToken: () => localStorage.getItem('access_token'),
    getUserPermissions: state => state.user_permissions,
    getUserRoles: state => state.user_roles,
    getUserFirstName: state => state.user_first_name,
    getLoggedInUserId: state => state.user_id,
    getLoggedInUser: state => state.logged_in_user,
    getSchoolName: state => state.school_name,
    getSchoolNameAbbreviation: state => state.school_name_abbreviation,
    getDomain: state => state.domain,
    getUserPaymentRequest: state => state.user_payment_request,
    getNavbarCollapsed: state => state.navbar_collapsed,
  },
  mutations: {
    setAccessToken(state, access_token) {
      state.access_token = access_token
    },
    setTokenType(state, token_type) {
      state.token_type = token_type
    },
    setUserPermissions(state, user_permissions) {
      state.user_permissions = user_permissions
    },
    setUserRoles(state, user_roles) {
      state.user_roles = user_roles
    },
    setUserFirstName(state, user_first_name) {
      state.user_first_name = user_first_name
    },
    setLoggedInUserId(state, user_id) {
      state.user_id = user_id
    },
    setLoggedInUser(state, logged_in_user) {
      state.logged_in_user = logged_in_user
    },
    setUserPaymentRequest(state, user_payment_request) {
      state.user_payment_request = user_payment_request
    },
    setNavbarCollapsed(state, navbar_collapsed) {
      state.navbar_collapsed = navbar_collapsed
    },
  },
  actions: {

    setLoggedInData({ commit }, payload) {
      //set the data from the payload
      let access_token = payload.access_token;
      let token_type = payload.token_type;
      let user_permissions = payload.user.user_permissions;
      let user_roles = payload.user.user_roles;
      let user_first_name = payload.user.firstname;
      let user_id = payload.user.id;
      let logged_in_user = payload.user;
      //commit the data to the state
      commit("setAccessToken", access_token);
      commit("setTokenType", token_type);
      commit("setUserPermissions", user_permissions);
      commit("setUserRoles", user_roles);
      commit("setUserFirstName", user_first_name);
      commit("setLoggedInUserId", user_id);
      //set the token to the local storage
      localStorage.setItem('access_token', access_token);
      localStorage.setItem('user_permissions', user_permissions);
      localStorage.setItem('user_roles', user_roles);
      localStorage.setItem('user_first_name', user_first_name);
      localStorage.setItem('user_id', user_id);
      localStorage.setItem('logged_in_user', logged_in_user);
    },

    logout({ commit }) {
      if (this.getters.isAuthenticated)
        axios.post('/api/logout');
      commit("setAccessToken", null);
      commit("setTokenType", null);
      commit("setUserPermissions", null);
      commit("setUserRoles", null);
      commit("setUserFirstName", null);
      localStorage.removeItem('access_token');
      localStorage.removeItem('user_permissions');
      localStorage.removeItem('user_roles');
      localStorage.removeItem('user_first_name');
      localStorage.removeItem('user_id');
      localStorage.removeItem('logged_in_user');
      window.location.assign('/sign-up');
    },

    // eslint-disable-next-line no-unused-vars
    handleException({ commit }, e) {

      if (e.response && e.response.status) {
        //handle token is expired
        if (e.response.status == 401 && e.response.data && e.response.data.message && e.response.data.message == 'Unauthenticated.') {
          this.dispatch('logout')
        }

        //handle the validation error
        if (e.response.status === 422) {
          return e.response.data.errors
        }

        if (e.response.status === 500 && e.response.data && e.response.data.message) {
          return [e.response.data.message];
        }

      }
      //unkown case
      router.push({ name: '404' })
    },

    setUserPaymentRequest({ commit }, payload) {
      commit("setUserPaymentRequest", payload);
    },


  },
  modules: {}
})
