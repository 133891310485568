// plugins/permissions.js
// import store from '@/store'

export default {
  install: (app) => {
    // inject a globally available $customRoute() method
    // you can access it like: $customRoute('download-application-file')
    app.config.globalProperties.$customRoute = (key) => {
      return key;
    }
  },

}